import React from 'react'
import {NavLink} from "react-router-dom";
import style from './MenuBarLink.module.css'

function MenuBarLink(props){

    const { to, children } = props

    const classes = (navData) => {
        return navData.isActive ?
                `${style['menu-bar-link__link']} ${style['menu-bar-link__link--active']}` :
                `${style['menu-bar-link__link']}`
    }

    return (
        <div className={style['menu-bar-link']}>
            <NavLink to={to} className={classes} end>{children}</NavLink>
            <div className={style['menu-bar-link__indicator']}></div>
        </div>
    )
}

export default MenuBarLink