import React from 'react'
import MobileMenuNavHeader from "./MobileMenuNavHeader/MobileMenuNavHeader";
import MobileMenuNavLinks from "./MobileMenuNavLinks/MobileMenuNavLinks";
import MobileMenuNavSocial from "./MobileMenuNavSocial/MobileMenuNavSocial";
import style from './MobileMenuNav.module.css'

function MobileMenuNav(){
    return (
        <div className={style['mb-menu-nav']}>
            <MobileMenuNavHeader/>
            <MobileMenuNavLinks/>
            <MobileMenuNavSocial/>
        </div>
    )
}

export default MobileMenuNav