import React from 'react'
import { NavLink } from "react-router-dom";
import style from './MobileMenuLink.module.css'

function MobileMenuLink(props){

    const { to, children } = props

    const classes = (navData) => {
        return navData.isActive ?
            `${style['mb-menu-link']} ${style['mb-menu-link--active']}` :
            `${style['mb-menu-link']}`
    }

    return (
        <NavLink to={to} className={classes} end>
            <div className={style['mb-menu-link__content']}>
                {children}
            </div>
            <div className={style['mb-menu-link__indicator']}></div>
        </NavLink>
    )
}

export default MobileMenuLink