import React from 'react'
import { Icon } from "@iconify/react";
import style from './MobileMenuNavSocialLinks.module.css'

function MobileMenuNavSocialLinks(){
    return (
        <div className={style['mb-menu-nav-social-links']}>
            <a href={'https://www.facebook.com'} target={'_blank'} rel={'noreferrer'}>
                <Icon icon="akar-icons:facebook-fill" />
            </a>
            <a href={'https://www.twitter.com'} target={'_blank'} rel={'noreferrer'}>
                <Icon icon="ant-design:twitter-circle-filled" />
            </a>
            <a href={'https://www.instagram.com'} target={'_blank'} rel={'noreferrer'}>
                <Icon icon="ant-design:instagram-filled" />
            </a>
            <a href={'https://www.discord.com'} target={'_blank'} rel={'noreferrer'}>
                <Icon icon="akar-icons:discord-fill" />
            </a>
        </div>
    )
}

export default MobileMenuNavSocialLinks