import React from 'react'
import style from './ComingSoon.module.css'

function ComingSoon(){
    return (
        <div className={style['coming-soon']}>
            <div className={style['coming-soon__content']}>
                <h1 className={style['coming-soon__title']}>Coming Soon</h1>
                <p className={style['coming-soon__message']}>A unique NFTs collection will be introduced into the blockchain soon, keep in touch with us to get the latest updates of this project</p>
            </div>
        </div>
    )
}

export default ComingSoon