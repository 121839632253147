import React from 'react'
import logo from './../../../../../assets/logo/HRLogo.png'
import test from './../../../../../assets/test/sillhoute-test.png'
import style from './DesktopMenuBarLogo.module.css'

function DesktopMenuBarLogo(){
    return (
        <div className={style['dt-menu-bar-logo']}>
            <img src={test} className={style['dt-menu-bar-logo__sillhoute']} alt="Sillhoute"/>
            <img src={logo} className={style['dt-menu-bar-logo__logo']} alt="Logo"/>
        </div>
    )
}

export default DesktopMenuBarLogo