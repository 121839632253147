import React, { Fragment, useState } from 'react'
import {Icon} from "@iconify/react/dist/iconify";
import MobileMenu from "../../../MobileMenu/MobileMenu";
import style from './MobileMenuToggle.module.css'

function MobileMenuToggle(){

    const [ activeMenu, setActiveMenu ]  = useState(false)

    const toggleMenu = () => {
        setActiveMenu((prevDate) => {
            return !prevDate
        })
    }

    return (
        <div className={style['mobile-menu-toggle']}>
            <Icon icon="eva:menu-fill" className={style['mb-menu-toggle__button']} onClick={toggleMenu}/>
            <MobileMenu onToggleMenu={toggleMenu} activeMenu={activeMenu}/>
        </div>
    )
}

export default MobileMenuToggle