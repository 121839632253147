import React from 'react'
import DesktopMenuBarLogo from "./DesktopMenuBarLogo/DesktopMenuBarLogo";
import DesktopMenuBarLinks from "./DesktopMenuBarLinks/DesktopMenuBarLinks";
import style from './DesktopMenuBar.module.css'

function DesktopMenuBar(){
    return (
        <div className={style['dt-menu-bar']}>
            <DesktopMenuBarLogo/>
            <DesktopMenuBarLinks/>
        </div>
    )
}

export default DesktopMenuBar