import React from 'react'
import {Icon} from "@iconify/react";
import style from './OpenSeaMobileIcon.module.css'

function OpenSeaMobileIcon(){
    return (
        <a href={'https://www.opensea.io'} target={'_blank'} rel={'noreferrer'}>
            <Icon icon="simple-icons:opensea" className={style['opensea-icon']}/>
        </a>
    )
}

export default OpenSeaMobileIcon