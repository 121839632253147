import React from 'react'
import { Icon } from '@iconify/react';
import MobileMenuToggle from "./MobileMenuToggle/MobileMenuToggle";
import OpenSeaMobileIcon from "./OpenSeaMobileIcon/OpenSeaMobileIcon";
import logo from './../../../../assets/logo/HRLogo.png'
import style from './MobileMenuBar.module.css'

function MobileMenuBar(){
    return (
        <div className={style['mb-menu-bar']}>
            <MobileMenuToggle/>
            <img src={logo} className={style['mb-menu-bar__logo']} alt="Logo"/>
            <OpenSeaMobileIcon/>
        </div>
    )
}

export default MobileMenuBar