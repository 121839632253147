import React from 'react'
import MobileMenuNavSocialLinks from "./MobileMenuNavSocialLinks/MobileMenuNavSocialLinks";
import style from './MobileMenuNavSocial.module.css'

function MobileMenuNavSocial(){
    return (
        <div className={style['mb-menu-nav-social']}>
            <MobileMenuNavSocialLinks/>
            <p className={style['mb-menu-nav-social__message']}>Keep Updated</p>
        </div>
    )
}

export default MobileMenuNavSocial