import React from 'react'
import MenuBarLink from "./MenuBarLink/MenuBarLink";
import style from './DesktopMenuBarLinks.module.css'
import {Icon} from "@iconify/react/dist/iconify";

function DesktopMenuBarLinks(){
    return (
        <div className={style['dt-menu-bar-links']}>
            <MenuBarLink to={'/'}>Home</MenuBarLink>
            <MenuBarLink to={'/collections'}>Collections</MenuBarLink>
            <MenuBarLink to={'/events'}>Events</MenuBarLink>
            <MenuBarLink to={'/join-us'}>Join Us</MenuBarLink>
            <MenuBarLink to={'/subscribe'}>Subscribe</MenuBarLink>
            <a href="https://opensea.io/" target={'_blank'} rel={'noreferrer'}>
                <Icon icon="simple-icons:opensea" className={style['dt-menu-bar-links__opensea']}/>
            </a>
        </div>
    )
}

export default DesktopMenuBarLinks