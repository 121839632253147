import React from 'react'
import Content from "./components/UI/Content/Content";
import ComingSoon from "./pages/ComingSoon/ComingSoon";
import {Routes, Route} from "react-router-dom";

function App() {
    return (
        <Content>
            <Routes>
                <Route path={'/'} element={<ComingSoon/>}/>
                <Route path={'/collections'} element={<ComingSoon/>}/>
                <Route path={'/events'} element={<ComingSoon/>}/>
                <Route path={'/join-us'} element={<ComingSoon/>}/>
                <Route path={'/subscribe'} element={<ComingSoon/>}/>
            </Routes>
        </Content>
    );
}

export default App;
