import React from 'react'
import {Icon} from "@iconify/react";
import logo from './../../../../../assets/logo/HRLogo.png'
import style from './MobileMenuNavHeader.module.css'

function MobileMenuNavHeader(){
    return (
        <div className={style['mb-menu-nav-header']}>
            <Icon icon="clarity:close-line"  className={style['mb-menu-nav-header__close']}/>
            <img src={logo} className={style['mb-menu-nav-header__logo']} alt="Logo"/>
        </div>
    )
}

export default MobileMenuNavHeader