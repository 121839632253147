import React from 'react'
import {Icon} from "@iconify/react";
import MobileMenuLink from "./MobileMenuLink/MobileMenuLink";
import style from './MobileMenuNavLinks.module.css'

function MobileMenuNavLinks(){
    return (
        <div className={style['mb-menu-nav-links']}>
            <MobileMenuLink to={'/'}>
                <Icon icon="clarity:home-line" />Home
            </MobileMenuLink>
            <MobileMenuLink to={'/collections'}>
                <Icon icon="fluent:collections-20-regular" />Collections
            </MobileMenuLink>
            <MobileMenuLink to={'/events'}>
                <Icon icon="clarity:nodes-line" />Events
            </MobileMenuLink>
            <MobileMenuLink to={'/join-us'}>
                <Icon icon="ph:handshake-light" />Join Us
            </MobileMenuLink>
            <MobileMenuLink to={'/subscribe'}>
                <Icon icon="fluent:megaphone-loud-24-regular" />Subscribe
            </MobileMenuLink>
        </div>
    )
}

export default MobileMenuNavLinks