import React from 'react'
import DesktopMenuBar from "../MenuBar/DesktopMenuBar/DesktopMenuBar";
import MobileMenuBar from "../MenuBar/MobileMenuBar/MobileMenuBar";
import style from './Layout.module.css'

function Layout(props){
    return (
        <div className={style['layout']}>
            <DesktopMenuBar/>
            <MobileMenuBar/>
            {props.children}
        </div>
    )
}

export default Layout