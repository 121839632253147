import React from 'react'
import MobileMenuNav from "./MobileMenuNav/MobileMenuNav";
import style from './MobileMenu.module.css'

function MobileMenu(props){

    const { onToggleMenu, activeMenu } = props

    const classes = activeMenu ? `${style['mobile-menu']} ${style['mobile-menu--active']}` : `${style['mobile-menu']}`

    return (
        <div className={classes} onClick={onToggleMenu}>
            <MobileMenuNav/>
        </div>
    )
}

export default MobileMenu